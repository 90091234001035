import { Box } from "@mui/material";
import Button from "../atoms/Button";

interface ConnectionFailedParams {
}

const ConnectionFailed = ({
}: ConnectionFailedParams) => (
    <Box>
        <h4>
            Failed to connect to Backend Service.
        </h4>
        <Button
            variant="contained"
            onClick={() => { window.location.reload(); }}
        >
            Retry
        </Button>
    </Box>
);

export default ConnectionFailed;

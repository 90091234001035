import { Avatar, Box, Button, Typography } from "@mui/material";
import ColorHash from 'color-hash';
import { useMsal } from "@azure/msal-react";

import globalStyles from "../../globalStyles";

interface ProfileParams {
    user: any;
}

const Profile = ({
    user,
}: ProfileParams) => {
    const { instance } = useMsal();
    const splits = user.name.split(' ');
    const inits = `${splits[0]?.charAt(0) ?? ""}${splits[1]?.charAt(0) ?? ""}`;
    const colorHash = new ColorHash({
        lightness: 0.5,
        hue: { min: 90, max: 270 },
    });

    return (
        <Box sx={[globalStyles.ma1, globalStyles.px1, globalStyles.col]}>
            <Box
                sx={[
                    globalStyles.flexCenterStart,
                    { width: '240px' },
                ]}
            >
                {
                    user?.imageUrl ? (
                        <Avatar
                            alt={user.name}
                            src={user?.imageUrl}
                            sx={globalStyles.mr1}
                        />
                    ) : (
                        <Avatar
                            alt={user.name}
                            sx={[
                                { bgcolor: colorHash.hex(inits) },
                                globalStyles.mr1,
                            ]}
                        >
                            {inits}
                        </Avatar>
                    )
                }
                <Box>
                    <Typography
                        variant="h6"
                        sx={[globalStyles.pa0, { fontSize: '16px' }]}
                    >
                        {user.name}
                    </Typography>
                    <Typography sx={[globalStyles.pb1, globalStyles.px0, globalStyles.pt0, { fontSize: '12px' }]}>
                        {user.email || user.username}
                    </Typography>
                </Box>
            </Box>
            <Button
                type="button"
                variant="text"
                sx={[{ textTransform: 'none', alignSelf: 'flex-end' }, globalStyles.pa0]}
                onClick={() => {
                    instance.logoutRedirect();
                }}
            >
                Sign out
            </Button>
        </Box>
    );
};

export default Profile;

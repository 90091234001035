import { Box } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Document } from "react-pdf";

import { RootState } from "../../configureStore";
import YearPage from "../molecules/YearPage";

interface IssueCenterColumnParams {
}

const IssueCenterColumn = ({
}: IssueCenterColumnParams) => {
    const pdfUrl = useSelector<RootState, string | undefined>(({ bus }) => bus.yearPdf?.pdfUrl);
    const [retryRender, _setRetryRender] = useState<number>(0);
    const [rendered, setRendered] = useState<boolean>(false);

    return (
        <Box sx={[]}>
            <Document
                file={pdfUrl}
                renderMode="canvas"
                className="flex-center-center col"
                onError={(err) => {
                    console.error(err);
                }}
            >
                <YearPage
                    rendered={rendered}
                    setRendered={setRendered}
                    retryRender={retryRender}
                />
            </Document>
        </Box>
    );
};

export default IssueCenterColumn;

import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import IssueCenterColumn from "./organisms/IssueCenterColumn";
import IssueLeftColumn from "./organisms/IssueLeftColumn";
import { RootState } from "../configureStore";
import IssueRightColumn from "./organisms/IssueRightColumn";

interface IssueConnectionParams {
}

const IssueConnection = ({
}: IssueConnectionParams) => {
    const isMobile = useSelector<RootState, boolean>(({ status: { isMobile: im } }) => im || false);

    return (
        <Grid container spacing={2}>
            <Grid item xs={isMobile ? 12 : 2}>
                <IssueLeftColumn />
            </Grid>
            <Grid item xs={isMobile ? 12 : 7} alignItems="center" justifyContent="center">
                <IssueCenterColumn />
            </Grid>
            <Grid item xs={isMobile ? 12 : 3}>
                <IssueRightColumn />
            </Grid>
        </Grid>
    );
};

export default IssueConnection;

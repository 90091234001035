import { MenuItem, Select, SelectChangeEvent, TableCell, TableRow, Typography, colors } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import { RequestForm } from "../../interface/message";
import globalStyles from "../../globalStyles";
import Button from "../atoms/Button";
import { useWebSocketContext } from "../../WebSocketContext";
import { AppDispatch, RootState } from "../../configureStore";
import { ADD_TEST, ADD_YEAR_CHANGE } from "../../redux/reducer/bus";

interface FormYearDisplayParams {
    request: RequestForm;
}

const arr = ['2020', '2021', '2022', '2023', '2024', '2025', '2026'];

const FormYearDisplay = ({
    request,
}: FormYearDisplayParams) => {
    const loading = useSelector<RootState, boolean>(({ bus: { testing } }) => testing.includes(request.id));

    const dispatch = useDispatch<AppDispatch>();

    const { sendJsonMessage } = useWebSocketContext();
    const [taxYear, setTaxYear] = useState(request.taxPeriod);

    return (
        <TableRow sx={[globalStyles.mb1, { backgroundColor: request.isMultiYear ? colors.grey.A400 : undefined }]}>
            <TableCell><Typography fontSize={14} noWrap>{request.status}</Typography></TableCell>
            <TableCell>
                {
                    (request.taxPeriod.startsWith('Year') && request.status.startsWith('TIA')) ? (
                        <Select
                            onChange={(e: SelectChangeEvent) => {
                                setTaxYear(e.target.value);
                                dispatch({ type: ADD_YEAR_CHANGE, yearChange: { id: request.id, taxPeriod: e.target.value } });
                            }}
                        >
                            {
                                arr.map((yr) => (
                                    <MenuItem key={`year-select-${yr}`} value={yr}>{yr}</MenuItem>
                                ))
                            }
                        </Select>
                    ) : (
                        <Typography fontSize={14}>{request.taxPeriod}</Typography>
                    )
                }
            </TableCell>
            <TableCell>
                <Button
                    loading={loading}
                    onClick={() => {
                        dispatch({
                            type: ADD_TEST,
                            id: request.id,
                        });
                        const reqToSend = request.businessName ? {
                            tin: request.tin.replace(/-/g, ''),
                            productType: request.productType,
                            formNumber: request.formNumber,
                            purposeType: 'Mortgage Industry',
                            taxYear,
                            taxPayerType: request.taxPayerType,
                            taxPeriod: '12',
                            custFileNum: request.custFileNum,
                            businessName: request.businessName,
                        } : {
                            tin: request.tin.replace(/-/g, ''),
                            firstName: request.firstName,
                            lastName: request.lastName,
                            productType: request.productType,
                            formNumber: request.formNumber,
                            purposeType: 'Mortgage Industry',
                            taxYear,
                            taxPayerType: request.taxPayerType,
                            taxPeriod: '12',
                            custFileNum: request.custFileNum,
                        };

                        sendJsonMessage({
                            command: 'test-year',
                            id: request.id,
                            request: reqToSend,
                        });
                    }}
                    variant="contained"
                    size="small"
                    disabled={!request.status.startsWith('TIA')}
                >
                    Test
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default FormYearDisplay;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Checkbox } from "@mui/material";

import { RootState } from "../../configureStore";
import globalStyles from "../../globalStyles";
import Button from "../atoms/Button";
import { useWebSocketContext } from "../../WebSocketContext";
import { YearChange } from "../../interface/message";

interface IssueRightColumnParams {
}

const IssueRightColumn = ({
}: IssueRightColumnParams) => {
    const yearChanges = useSelector<RootState, YearChange[]>(({ bus: { yearReview: yr } }) => yr?.yearChange ?? []);
    const isAllGood = useSelector<RootState, boolean>(({ bus: { isAllGood: ag } }) => ag);
    const hasLoan = useSelector<RootState, boolean>(({ bus: { yearReview } }) => Boolean(yearReview?.FICode || yearReview?.irsIncomeId));
    const FICode = useSelector<RootState, string | undefined>(({ bus: { yearReview } }) => yearReview?.FICode);
    const finish = useSelector<RootState, boolean>(({ bus: { stopWaiting: sw } }) => sw);
    const [clicked, setClicked] = useState<boolean>(false);
    const [noEmail, setNoEmail] = useState<boolean>(false);

    const { sendJsonMessage } = useWebSocketContext();

    useEffect(() => {
        if (!hasLoan) {
            setClicked(false);
            setNoEmail(false);
        }
    }, [hasLoan]);

    return (
        <Box sx={globalStyles.col}>
            <Checkbox
                checked={noEmail}
                onChange={() => setNoEmail(!noEmail)}
                title="No email send"
            />
            <Button
                variant="contained"
                sx={globalStyles.my2}
                color={isAllGood ? 'success' : 'error'}
                disabled={!hasLoan}
                loading={clicked}
                onClick={() => {
                    setClicked(true);
                    if (yearChanges.length) {
                        sendJsonMessage({
                            command: 'issue-update-year',
                            yearChanges,
                            finish,
                            fiCode: FICode,
                            noEmail,
                        });
                    } else if (isAllGood) {
                        sendJsonMessage({
                            command: 'issue-resubmit',
                            finish,
                            noEmail,
                        });
                    } else {
                        sendJsonMessage({
                            command: 'issue-notify',
                            finish,
                            noEmail,
                        });
                    }
                }}
            >
                {yearChanges.length ? 'Update Year' : isAllGood ? 'Resubmit to IRS' : 'Submit Action Needed'}
            </Button>
            <Button
                variant="contained"
                sx={globalStyles.my2}
                color="error"
                disabled={!hasLoan}
                loading={clicked}
                onClick={() => {
                    setClicked(true);
                    sendJsonMessage({
                        command: 'issue-nuke',
                        finish,
                    });
                }}
            >
                Nuke
            </Button>
        </Box>
    );
};

export default IssueRightColumn;

import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";

import globalStyles from "../../globalStyles";
import { AppDispatch, RootState } from "../../configureStore";
import Button from "../atoms/Button";
import { useWebSocketContext } from "../../WebSocketContext";
import { SET_PROCESSING, SET_TAB_ID, START_WAITING, STOP_WAITING } from "../../redux/reducer/bus";
import { hotKeysConfig } from "../../utils";

interface LeftColumnParams {
}

const LeftColumn = ({
}: LeftColumnParams) => {
    const dispatch = useDispatch<AppDispatch>();

    const isMobile = useSelector<RootState, boolean>(({ status: { isMobile: im } }) => im || false);
    const count = useSelector<RootState, number>(({ bus: { count: ct } }) => ct);
    const timeout = useSelector<RootState, boolean>(({ bus: { timeout: to } }) => to);
    const stopWaiting = useSelector<RootState, boolean>(({ bus: { stopWaiting: sw } }) => sw);
    const processing = useSelector<RootState, boolean>(({ bus: { processing: pr } }) => pr);
    const hasLoan = useSelector<RootState, boolean>(({ bus: { request } }) => Boolean(request?.FICode || request?.irsIncomeID));
    const req = useSelector<RootState, { institutionCode: string; loanNumber: string; } | undefined>(({ bus: { request } }) => request);

    useEffect(() => {
        if (timeout) dispatch({ type: SET_PROCESSING, processing: false });
    }, []);

    const { sendJsonMessage } = useWebSocketContext();

    return (
        <Box className="stuck-column">
            <Box sx={[globalStyles.box, isMobile ? { ...globalStyles.row, ...globalStyles.justifyBetween } : globalStyles.col]}>
                <Box
                    sx={[
                        globalStyles.pa2,
                        globalStyles.brXs,
                        count > 15 ? globalStyles.danger : count > 5 ? globalStyles.yellow : globalStyles.success,
                    ]}
                >
                    <Typography variant="h5">{count}</Typography>
                    <Typography>Waiting Approval</Typography>
                </Box>
                <Box sx={globalStyles.col}>
                    <Button
                        id="next-btn"
                        variant="contained"
                        disabled={hasLoan}
                        loading={processing || !stopWaiting}
                        sx={[globalStyles.mt2]}
                        onClick={() => {
                            const newTabId = v4();
                            dispatch({ type: START_WAITING });
                            dispatch({ type: SET_PROCESSING, processing: true });
                            dispatch({ type: SET_TAB_ID, tabId: newTabId });
                            try {
                                sendJsonMessage({ command: 'register', tabId: newTabId });
                            } catch (err) {
                                console.error(err);
                            }
                        }}
                    >
                        Next Request
                    </Button>
                    <Button
                        id="stop-btn"
                        variant="contained"
                        color="secondary"
                        disabled={stopWaiting}
                        sx={[globalStyles.mt2]}
                        onClick={() => {
                            if (processing) {
                                dispatch({ type: SET_PROCESSING, processing: false });
                            }
                            dispatch({ type: STOP_WAITING });
                            if (processing && !hasLoan) {
                                sendJsonMessage({ command: 'deregister' });
                            }
                        }}
                    >
                        Stop Waiting
                    </Button>
                </Box>
            </Box>
            {hasLoan && (
                <Box sx={[globalStyles.box, globalStyles.mt2]}>
                    <Typography fontSize={16} variant="h6">Loan Information</Typography>
                    <Typography fontSize={14}>{req?.institutionCode}</Typography>
                    <Typography fontSize={14}>{req?.loanNumber}</Typography>
                </Box>
            )}
            <Box sx={[globalStyles.box, globalStyles.mt2]}>
                <Typography fontSize={14} variant="h6">Shortcuts</Typography>
                {
                    hotKeysConfig.map(({ action, button }) => (
                        <Box key={`hotkey-${button}`} sx={[globalStyles.flexCenterStart, { borderBottom: '1px solid black' }]}>
                            <Typography minWidth={80} sx={globalStyles.pl1} textAlign="left" fontSize={12}>{button}</Typography>
                            <Typography fontSize={12} textAlign="left">{action}</Typography>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    );
};

export default LeftColumn;

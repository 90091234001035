import { ReactElement, useState } from "react";
import { Avatar, Box, Menu, MenuItem, MenuProps } from "@mui/material";

import Icon, { IconParams } from "./Icon";

interface IconMenuBaseParams extends Omit<MenuProps, 'open'> {
    keyPrefix?: string;
    name?: string;
    iconParams?: IconParams;
    imageUrl?: string;
}

interface IconMenuItemsParams extends IconMenuBaseParams {
    items: {
        onClick?: (_e: any) => void;
        disabled?: boolean;
        item: ReactElement | string;
    }[];
}

interface IconMenuItemParams extends IconMenuBaseParams {
    item: ReactElement
}

type IconMenuParams = IconMenuItemsParams | IconMenuItemParams;

const IconMenu = (elProps: IconMenuParams) => {
    const {
        keyPrefix = '',
        name = "MoreVert",
        iconParams,
        imageUrl,
        ...props
    } = elProps;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(!open);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {imageUrl ? (
                <Box
                    component="button"
                    sx={{ border: 'none', cursor: 'pointer', padding: 0, background: 'none' }}
                    onClick={handleClick}
                >
                    <Avatar
                        alt="Avatar"
                        src={imageUrl}
                    />
                </Box>
            ) : (
                <Icon
                    {...iconParams}
                    name={name}
                    onClick={handleClick}
                />
            )}
            <Menu
                {...props}
                anchorEl={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                open={open}
            >
                {('items' in elProps) ? elProps.items.map((item, idx) => (
                    <MenuItem
                        key={`${keyPrefix}-menu-item-${idx}`}
                        onClick={item.onClick}
                        disabled={item.disabled}
                    >
                        {item.item}
                    </MenuItem>
                )) : (elProps.item)}
            </Menu>
        </>
    );
};

export default IconMenu;

/* eslint-disable import/no-cycle */
import { combineReducers } from "redux";
import bus from "./bus";
import status from "./status";

export interface Payload<T = any> {
    onSuccess?: (data: any) => void;
    onError?: (data: any) => void;
    headers?: T;
    input?: T;
    data?: T;
    noLoad?: boolean;
}

const rootReducer = combineReducers({
    bus,
    status,
});

export default rootReducer;

import { Box } from "@mui/material";
import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Page } from "react-pdf";

import globalStyles from "../../globalStyles";
import Zoom from "../organisms/Zoom";
import { ImageScale } from "../../interface/ui";
import { RootState } from "../../configureStore";

interface YearPageParams {
    setRendered: (_: boolean) => void;
    rendered: boolean;
    retryRender: number;
}

const initScale = (): ImageScale => {
    try {
        const arr = JSON.parse(localStorage.getItem('scale') || '{}');
        if (!arr || !arr.year) throw new Error();
        return {
            year: 1,
            ...arr,
        };
    } catch {
        return {
            tin: 2,
            sig: 1,
            col: 1,
            year: 1,
        };
    }
};
const YearPage = ({
    setRendered,
    rendered,
    retryRender,
}: YearPageParams) => {
    const years = useSelector<RootState, number[]>(({ bus: { yearReview } }) => yearReview?.taxYears ?? []);
    const instCode = useSelector<RootState, string | undefined>(({ bus: { yearReview } }) => yearReview?.institutionCode);
    const initialScale = useMemo(() => initScale(), []);
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div>
            <Box sx={[globalStyles.flexCenterCenter, globalStyles.col]}>
                <Zoom
                    initialScale={initialScale}
                    page={1}
                    setPage={() => { }}
                    pageNumber={1}
                    pageRef={ref}
                    title="Years"
                    zoomType="year"
                    retryRender={retryRender}
                    rendered={rendered}
                    timeout={500}
                    years={years}
                    instCode={instCode}
                />
                <div ref={ref} id="form_page">
                    <Page
                        onRenderSuccess={() => { setRendered(true); }}
                        pageNumber={1}
                    />
                </div>
            </Box>
        </div>
    );
};

export default YearPage;

import { useEffect, useRef, useState } from "react";
import { Box, Checkbox, Slider, Typography } from "@mui/material";

import globalStyles from "../../globalStyles";
import { ImageScale, OffsetTypes, defaultOffsets } from "../../interface/ui";
import Icon from "../atoms/Icon";
import { renderUntoScreen } from "../../utils";
import YearChecks from "../molecules/YearChecks";

interface ZoomParams {
    zoomType: 'tin' | 'sig' | 'col' | 'year';
    initialScale: ImageScale;
    title?: string;
    pageRef: React.RefObject<HTMLDivElement>;
    page: number;
    setPage: (_: number) => void;
    pageNumber: number;
    retryRender: number;
    rendered: boolean;
    tinPage?: number;
    timeout?: number;
    years?: number[];
    instCode?: string;
}

const offsets: { [isntCode: string]: OffsetTypes } = {
    default: defaultOffsets,
    XPAN: {
        colHolder: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
        sigHolder: {
            x: 0,
            y: -64,
            height: 0,
            width: 0,
        },
        tinHolder: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
        yearHolder: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
    },
    FREE: {
        colHolder: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
        sigHolder: {
            x: 0,
            y: -64,
            height: 0,
            width: 0,
        },
        tinHolder: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
        yearHolder: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
    },
    NDMO: {
        colHolder: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
        sigHolder: {
            x: 0,
            y: -64,
            height: 0,
            width: 0,
        },
        tinHolder: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
        yearHolder: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
        },
    },
};

const Zoom = ({
    zoomType,
    initialScale,
    title,
    pageRef,
    page,
    setPage,
    pageNumber,
    retryRender,
    rendered,
    tinPage,
    timeout = 300,
    years = [],
    instCode = 'default',
}: ZoomParams) => {
    const [scale, setScale] = useState<number>(initialScale[zoomType]);
    const [showScale, setShowScale] = useState<boolean>(false);

    const innerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        renderUntoScreen({
            rendered,
            ref: pageRef,
            scaleTo: scale,
            innerRef,
            holderName: `${zoomType}Holder`,
            timeout,
            offset: offsets?.[instCode] ?? offsets.default,
        });
    }, [pageNumber, pageRef, rendered, scale, retryRender]);

    useEffect(() => {
        const scaleStore = JSON.parse(localStorage.getItem('scale') || '{}');
        localStorage.setItem('scale', JSON.stringify({ ...scaleStore, [zoomType]: scale }));
    }, [scale]);

    return (
        <Box
            sx={[
                globalStyles.flexCenterCenter,
                globalStyles.col,
                globalStyles.border,
                globalStyles.mb2,
                globalStyles.white,
                globalStyles.pa2,
                {
                    borderWidth: 2,
                    minHeight: 120,
                    zIndex: 200,
                },
            ]}
        >
            <Box
                sx={[
                    globalStyles.flex,
                ]}
            >
                <Box>
                    <Typography
                        variant="h6"
                        sx={[
                            globalStyles.ma0,
                            globalStyles.darkBlueText,
                            {
                                fontWeight: 800,
                                borderBottomWidth: 1,
                                borderBottomColor: 'black',
                                borderBottomStyle: 'solid',
                                fontSize: ((scale / 2) * 30) > 20 ? 20 : ((scale / 2) * 30),
                            },
                        ]}
                    >
                        {title}
                    </Typography>
                    <div ref={innerRef}>
                        <div id={`${zoomType}Holder`} />
                    </div>
                </Box>
                <Box
                    sx={[globalStyles.flexCenterBetween, globalStyles.col]}
                >
                    <Icon
                        name="Settings"
                        onClick={() => {
                            if (!showScale) {
                                renderUntoScreen({
                                    rendered,
                                    ref: pageRef,
                                    scaleTo: scale,
                                    innerRef,
                                    holderName: `${zoomType}Holder`,
                                    offset: offsets?.[instCode] ?? offsets.default,
                                });
                            }
                            setShowScale(!showScale);
                        }}
                        fontSize="small"
                    />
                    {
                        showScale ? (
                            <Box>
                                <Slider
                                    value={scale}
                                    onChange={(_, value) => { setScale(value as number); }}
                                    orientation="vertical"
                                    style={{ height: 78 }}
                                    min={0.5}
                                    max={1.25}
                                    step={0.25}
                                />
                            </Box>
                        ) : <Box sx={{ width: 30, height: 80 }} />
                    }
                </Box>
            </Box>
            {zoomType === 'year' ? years.length ? (
                <Box sx={[globalStyles.flexCenterEnd, { alignSelf: 'flex-end' }]}>
                    <YearChecks years={years} />
                </Box>
            ) : <div /> : (
                <Box sx={[globalStyles.flexCenterEnd, { alignSelf: 'flex-end' }]}>
                    <Typography sx={{ fontSize: ((scale / 2) * 30) > 12 ? 12 : ((scale / 2) * 30) }}>
                        {zoomType === 'tin'
                            ? 'Has Correct TIN on this page:'
                            : zoomType === 'sig'
                                ? 'Has Signature on this page:'
                                : 'Proper Business Form:'}
                    </Typography>
                    <Checkbox
                        id={`${zoomType}-check`}
                        checked={page > 0}
                        onClick={() => { setPage(page ? 0 : pageNumber); }}
                        disabled={Boolean((page && page !== pageNumber) || (zoomType !== 'tin' && !page && tinPage !== pageNumber))}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Zoom;

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { RootState } from "../../configureStore";
import Button from "../atoms/Button";
import { useWebSocketContext } from "../../WebSocketContext";
import globalStyles from "../../globalStyles";
import Issues from "../molecules/Issues";
import { IReason } from "../../redux/reducer/bus";

interface RightColumnParams {
}

const RightColumn = ({
}: RightColumnParams) => {
    const hasRequest = useSelector<RootState, boolean>(({ bus: { request } }) => Boolean(request));
    const pageNum = useSelector<RootState, number | null>(({ bus: { pageNumber } }) => pageNumber);
    const reasons = useSelector<RootState, { [id: string]: IReason; }>(({ bus: { reasons: rs } }) => rs);
    const issue = useSelector<RootState, string | null>(({ bus: { issue: is } }) => is);
    const finish = useSelector<RootState, boolean>(({ bus: { stopWaiting: sw } }) => sw);

    const { sendJsonMessage } = useWebSocketContext();

    const [clicked, setClicked] = useState<boolean>(false);

    useEffect(() => {
        sendJsonMessage({ command: 'reasons' });
    }, []);

    useEffect(() => {
        if (!hasRequest) {
            setClicked(false);
        }
    }, [hasRequest]);

    const blockingIssue = useMemo(() => (issue && reasons[issue].isBlocking), [reasons, issue]);

    return (
        <Box className="stuck-column">
            <Button
                id="complete-btn"
                disabled={blockingIssue ? false : pageNum === null}
                sx={globalStyles.my2}
                color={issue ? 'error' : 'success'}
                variant="contained"
                loading={clicked}
                onClick={() => {
                    setClicked(true);
                    const rs = reasons[issue || ''];
                    if (rs && rs.isNuke) {
                        sendJsonMessage({ command: 'nuke', finish });
                    } else {
                        sendJsonMessage({
                            command: 'complete',
                            issue,
                            finish,
                            pageNum,
                        });
                    }
                }}
            >
                {issue ? 'Submit Action Needed' : 'Submit to IRS'}
            </Button>
            {hasRequest && <Issues />}
        </Box>
    );
};

export default RightColumn;

import { createContext, useContext } from "react";
import { ReadyState, SendMessage } from "react-use-websocket";
import { SendJsonMessage, WebSocketLike } from "react-use-websocket/dist/lib/types";

export interface WebSocketContextProps {
    sendMessage: SendMessage;
    sendJsonMessage: SendJsonMessage;
    lastMessage?: WebSocketEventMap['message'] | null;
    lastJsonMessage?: unknown;
    readyState: ReadyState;
    getWebSocket: () => (WebSocketLike | null);
}

export const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined);

export const useWebSocketContext = (): WebSocketContextProps => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocketContext must be used within a WebSocketProvider');
    }
    return context;
};

import { Box, Radio, RadioGroup, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../configureStore";
import globalStyles from "../../globalStyles";
import { IReason, SET_ISSUE } from "../../redux/reducer/bus";

interface IssuesParams {
}

const Issues = ({
}: IssuesParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const reasons = useSelector<RootState, { [id: string]: IReason; }>(({ bus: { reasons: rs } }) => rs);
    const issue = useSelector<RootState, string | null>(({ bus: { issue: is } }) => is);

    return (
        <Box sx={globalStyles.box}>
            <RadioGroup value={issue}>
                {Object.keys(reasons).map((reasonId, idx) => (
                    <Box
                        key={reasonId}
                        sx={[
                            globalStyles.ma0,
                            globalStyles.flex,
                            globalStyles.flexCenterStart,
                            globalStyles.borderB,
                        ]}
                    >
                        <Radio
                            id={`reason-${idx}`}
                            sx={[{ paddingTop: '1px', paddingBottom: '2px' }, globalStyles.px1]}
                            value={reasonId}
                            onClick={() => {
                                if (issue === reasonId) dispatch({ type: SET_ISSUE, issue: null });
                                else dispatch({ type: SET_ISSUE, issue: reasonId });
                            }}
                        />
                        <Typography fontSize={12} sx={{ overflowX: 'hidden' }}>{reasons[reasonId].scenario}</Typography>
                    </Box>
                ))}
            </RadioGroup>
        </Box>
    );
};

export default Issues;

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import store from "./configureStore";

import './App.css';
import { msalConfig } from './authConfig';
import OperationsApp from './OperationsApp';

function App() {
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.initialize();

    return (
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <OperationsApp />
            </Provider>
        </MsalProvider>
    );
}

export default App;

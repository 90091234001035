import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { ReadyState } from "react-use-websocket";

import { RootState } from "../../configureStore";
import globalStyles from "../../globalStyles";
import Timeout from "../Timeout";
import { useWebSocketContext } from "../../WebSocketContext";
import Loading from "../Loading";
import ConnectionFailed from "./ConnectionFailed";
import IssueConnection from "../IssueConnection";

interface IssuesParams {
}

const Issues = ({
}: IssuesParams) => {
    const isMobile = useSelector<RootState, boolean>(({ status: { isMobile: im } }) => im || false);
    const timeout = useSelector<RootState, boolean>(({ bus: { timeout: to } }) => to);
    const cnt = useSelector<RootState, number>(({ bus: { issueCount } }) => issueCount);

    const { readyState } = useWebSocketContext();

    const [trying, _setTrying] = useState<boolean>(true);
    const [tooMany, _setTooMany] = useState<boolean>(false);
    const isReady = useMemo<boolean>(() => (readyState === ReadyState.OPEN && cnt > -1), [cnt, readyState]);

    return (
        <Box
            sx={{
                display: "flex",
                flex: 1,
                backgroundColor: timeout ? '#F05379' : undefined,
            }}
        >
            <Box
                component="main"
                sx={[
                    {
                        flex: 1,
                        background: "#f7f5f5",
                        paddingBottom: 1,
                        color: "black",
                        backgroundColor: timeout ? '#F05379' : undefined,
                    },
                    globalStyles.px4,
                    { ...isMobile ? globalStyles.pt1 : globalStyles.pt8 },
                ]}
            >
                {timeout
                    ? <Timeout />
                    : tooMany
                        ? <div>System is at capacity.</div>
                        : trying
                            ? isReady
                                ? (
                                    <IssueConnection />
                                )
                                : <Loading />
                            : <ConnectionFailed />}
            </Box>
        </Box>
    );
};

export default Issues;

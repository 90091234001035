import { UnknownAction, configureStore } from '@reduxjs/toolkit';
import { ThunkDispatch, thunk } from 'redux-thunk';
import { Dispatch } from 'react';
// eslint-disable-next-line import/no-cycle
import rootReducer from './redux/reducer';

const store = configureStore({
    reducer: rootReducer,

    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = Dispatch<UnknownAction> & ThunkDispatch<RootState, null, UnknownAction>

export default store;

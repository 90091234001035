/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */

import { IssueMessage, RequestForm, StatusUpdateHistory, YearChange } from "../../interface/message";

export type Topic = 'review' | 'issue';

const nukeReasons: {
    [id: string]: {
        isBlocking: boolean;
        scenario: string;
        message: string;
        isNuke?: boolean;
    }
} = {
    NUKE1: {
        isBlocking: true,
        scenario: 'Duplicate 8821 upload',
        message: 'Duplicate 8821 upload',
        isNuke: true,
    },
};

export interface IReason {
    isBlocking: boolean;
    scenario: string;
    message: string;
    isNuke?: boolean;
}

const initialState: {
    tabId?: string;
    isConnected: boolean;
    count: number;
    issueCount: number;
    reasons: {
        [id: string]: IReason;
    };
    ws?: WebSocket;
    request?: {
        FICode: string;
        irsIncomeID: string;
        tin: string;
        isBusiness: boolean;
        firstName?: string;
        lastName?: string;
        institutionCode: string;
        loanNumber: string;
    };
    b64?: string;
    pdfUrl?: string;
    stopWaiting: boolean;
    pageNumber: number | null;
    issue: string | null;
    timeout: boolean;
    processing: boolean;
    topic: Topic;
    yearReview?: IssueMessage;
    yearPdf?: {
        b64: string;
        pdfUrl: string;
    };
    isAllGood: boolean;
    testing: string[];
} = {
    tabId: undefined,
    isConnected: false,
    count: -1,
    issueCount: -1,
    reasons: {},
    stopWaiting: true,
    pageNumber: null,
    issue: null,
    timeout: false,
    processing: false,
    isAllGood: false,
    topic: 'review',
    testing: [],
};

export const ADD_YEAR_CHANGE = 'bus/ADD_YEAR_CHANGE';
export const REMOVE_YEAR_CHANGE = 'bus/REMOVE_YEAR_CHANGE';

export const CONNECT_REQUEST = 'bus/CONNECT_REQUEST';
export const CONNECT_SUCCESS = 'bus/CONNECT_SUCCESS';
export const CONNECT_FAILURE = 'bus/CONNECT_FAILURE';

export const ALL_YEARS_GOOD = 'bus/ALL_YEARS_GOOD';

export const COUNT_SUCCESS = 'bus/COUNT_SUCCESS';
export const ISSUE_COUNT_SUCCESS = 'bus/ISSUE_COUNT_SUCCESS';

export const REQUEST_COMPLETED = 'bus/REQUEST_COMPLETED';
export const REQUEST_RECEIVED = 'bus/REQUEST_RECEIVED';
export const RESET_REQUEST = 'bus/RESET_REQUEST';

export const ISSUE_RECEIVED = 'bus/ISSUE_RECEIVED';
export const ISSUE_DOWNLOAD = 'bus/ISSUE_DOWNLOAD';

export const TIMED_OUT = 'bus/TIMED_OUT';
export const TIMED_IN = 'bus/TIMED_IN';

export const DOWNLOAD_SUCCESS = 'bus/DOWNLOAD_SUCCESS';
export const REASON_SUCCESS = 'bus/REASON_SUCCESS';

export const SET_TAB_ID = 'bus/SET_TAB_ID';

export const STOP_WAITING = 'bus/STOP_WAITING';
export const START_WAITING = 'bus/START_WAITING';
export const SET_PROCESSING = 'bus/SET_PROCESSING';

export const CLOSED = 'bus/CLOSED';
export const SET_ISSUE = 'bus/SET_ISSUE';

export const SET_PAGE_NUMBER = 'bus/SET_PAGE_NUMBER';

export const SET_TOPIC = 'bus/SET_TOPIC';

export const ADD_TEST = 'bus/ADD_TEST';
export const DONE_TEST = 'bus/DONE_TEST';

type ActionParams =
    | { type: typeof ALL_YEARS_GOOD; isAllGood: boolean; }
    | { type: typeof CONNECT_REQUEST; tabId: string; }
    | { type: typeof CLOSED; }
    | { type: typeof ADD_TEST; id: string; }
    | { type: typeof DONE_TEST; id: string; }
    | { type: typeof CONNECT_SUCCESS; }
    | { type: typeof STOP_WAITING; }
    | { type: typeof START_WAITING; }
    | { type: typeof RESET_REQUEST; }
    | { type: typeof ADD_YEAR_CHANGE; yearChange: YearChange }
    | { type: typeof REMOVE_YEAR_CHANGE; id: string }
    | { type: typeof COUNT_SUCCESS; count: number; }
    | { type: typeof ISSUE_COUNT_SUCCESS; count: number; }
    | { type: typeof SET_PROCESSING; processing: boolean; }
    | {
        type: typeof REQUEST_RECEIVED;
        FICode: string;
        irsIncomeId: string;
        tin: string;
        businessName: string;
        firstName: string;
        lastName: string;
        institutionCode: string;
        loanNumber: string;
    }
    | { type: typeof DOWNLOAD_SUCCESS; b64: string; }
    | { type: typeof REQUEST_COMPLETED; }
    | { type: typeof TIMED_OUT; }
    | { type: typeof TIMED_IN; }
    | {
        type: typeof REASON_SUCCESS;
        reasons: {
            [id: string]: {
                isBlocking: boolean;
                scenario: string;
                message: string;
                isNuke?: boolean;
            }
        };
    }
    | { type: typeof SET_ISSUE; issue: string | null; }
    | { type: typeof SET_TAB_ID; tabId: string; }
    | { type: typeof SET_PAGE_NUMBER; pageNumber: number | null; }
    | { type: typeof SET_TOPIC; topic: Topic }
    | {
        type: typeof ISSUE_RECEIVED;
        FICode: string;
        irsIncomeId: string;
        taxYears: number[];
        businessName: string;
        firstName: string;
        institutionCode: string;
        lastName: string;
        loanNumber: string;
        tin: string;
        custFileNum: string;
        history: StatusUpdateHistory[];
        purposeType: string;
        taxPayerType: 'Individual' | 'Business';
        requests: RequestForm[];
    }
    | { type: typeof ISSUE_DOWNLOAD; b64: string; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case ADD_YEAR_CHANGE: {
            if (state.yearReview) {
                return {
                    ...state,
                    yearReview: {
                        ...state.yearReview,
                        yearChange: [...state.yearReview.yearChange, action.yearChange],
                    },
                };
            }
            return state;
        }

        case REMOVE_YEAR_CHANGE: {
            if (state.yearReview) {
                return {
                    ...state,
                    yearReview: {
                        ...state.yearReview,
                        yearChange: state.yearReview?.yearChange.filter(({ id }) => id !== action.id) ?? [],
                    },
                };
            }
            return state;
        }

        case ADD_TEST: {
            return {
                ...state,
                testing: [...state.testing, action.id],
            };
        }

        case DONE_TEST: {
            return {
                ...state,
                testing: state.testing.filter((id) => id !== action.id),
            };
        }

        case ISSUE_RECEIVED:
            return {
                ...state,
                yearReview: {
                    FICode: action.FICode,
                    irsIncomeId: action.irsIncomeId,
                    taxYears: action.taxYears,
                    businessName: action.businessName,
                    firstName: action.firstName,
                    institutionCode: action.institutionCode,
                    lastName: action.lastName,
                    loanNumber: action.loanNumber,
                    tin: action.tin,
                    custFileNum: action.custFileNum,
                    purposeType: action.purposeType,
                    history: action.history,
                    taxPayerType: action.taxPayerType,
                    yearChange: [],
                    requests: action.requests.reduce((acc, curr) => {
                        const added: RequestForm[] = acc[curr.formNumber] ?? [];
                        added.push(curr);
                        return {
                            ...acc,
                            [curr.formNumber]: added,
                        };
                    }, {} as { [formNumber: string]: RequestForm[] }),
                },
            };

        case ISSUE_DOWNLOAD:
            const byteCharactersIss = atob(action.b64);
            const byteNumbersIss = new Array(byteCharactersIss.length);
            for (let i = 0; i < byteCharactersIss.length; i++) {
                byteNumbersIss[i] = byteCharactersIss.charCodeAt(i);
            }
            const byteArrayIss = new Uint8Array(byteNumbersIss);
            const blobIss: Blob = new Blob([byteArrayIss], { type: 'application/pdf' });
            const pdfUrlIss: string = URL.createObjectURL(blobIss);

            return {
                ...state,
                yearPdf: {
                    b64: action.b64,
                    pdfUrl: pdfUrlIss,
                },
            };

        case ALL_YEARS_GOOD:
            return {
                ...state,
                isAllGood: action.isAllGood,
            };

        case CONNECT_REQUEST:
            return {
                ...state,
                tabId: action.tabId,
            };

        case CLOSED:
            return {
                ...state,
                isConnected: false,
            };

        case CONNECT_SUCCESS:
            return {
                ...state,
                isConnected: true,
            };

        case COUNT_SUCCESS:
            return {
                ...state,
                count: action.count,
            };

        case ISSUE_COUNT_SUCCESS:
            return {
                ...state,
                issueCount: action.count,
            };

        case SET_TAB_ID:
            return {
                ...state,
                tabId: action.tabId,
            };

        case REASON_SUCCESS: {
            return {
                ...state,
                reasons: { ...action.reasons, ...nukeReasons },
            };
        }

        case STOP_WAITING: {
            return {
                ...state,
                stopWaiting: true,
            };
        }

        case START_WAITING: {
            return {
                ...state,
                stopWaiting: false,
            };
        }

        case SET_PAGE_NUMBER: {
            return {
                ...state,
                pageNumber: action.pageNumber,
            };
        }

        case SET_ISSUE: {
            return {
                ...state,
                issue: action.issue,
            };
        }

        case REQUEST_RECEIVED:
            return {
                ...state,
                request: {
                    FICode: action.FICode,
                    irsIncomeID: action.irsIncomeId,
                    tin: action.tin,
                    isBusiness: Boolean(action.businessName),
                    firstName: action.firstName,
                    lastName: action.lastName,
                    institutionCode: action.institutionCode,
                    loanNumber: action.loanNumber,
                },
            };

        case REQUEST_COMPLETED: {
            return {
                ...state,
                request: undefined,
                b64: undefined,
                pdfUrl: undefined,
                issue: null,
                pageNumber: null,
                yearPdf: undefined,
                yearReview: undefined,
            };
        }

        case TIMED_OUT: {
            return {
                ...state,
                request: undefined,
                b64: undefined,
                pdfUrl: undefined,
                issue: null,
                pageNumber: null,
                timeout: true,
            };
        }

        case TIMED_IN: {
            return {
                ...state,
                timeout: false,
            };
        }

        case RESET_REQUEST: {
            return {
                ...state,
                request: undefined,
            };
        }

        case SET_PROCESSING: {
            return {
                ...state,
                processing: action.processing,
            };
        }

        case SET_TOPIC: {
            return {
                ...state,
                topic: action.topic,
            };
        }

        case DOWNLOAD_SUCCESS:
            const byteCharacters = atob(action.b64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob: Blob = new Blob([byteArray], { type: 'application/pdf' });
            const pdfUrl: string = URL.createObjectURL(blob);

            return {
                ...state,
                b64: action.b64,
                pdfUrl,
            };

        default:
            return state;
    }
}

export default reducer;

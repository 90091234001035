import {
    ButtonProps,
    LinearProgress,
    Button as MuiButton,
} from '@mui/material';

interface ButtonParams extends ButtonProps {
    loading?: boolean;
}

const Button = ({ loading, children, ...props }: ButtonParams) => (
    <MuiButton
        {...props}
        disabled={props.disabled || loading}
        style={{
            color: loading ? 'rgba(0, 0, 0, 0)' : undefined,
        }}
        className="hsw-button"
    >
        <>
            {loading && <LinearProgress style={{ position: 'absolute', width: '80%', margin: 8 }} color={props.color} />}
            {children}
        </>
    </MuiButton>
);

export default Button;

import { Box, Table, TableBody, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";

import globalStyles from "../../globalStyles";
import { AppDispatch, RootState } from "../../configureStore";
import Button from "../atoms/Button";
import { useWebSocketContext } from "../../WebSocketContext";
import { SET_PROCESSING, SET_TAB_ID, START_WAITING, STOP_WAITING } from "../../redux/reducer/bus";
import { IssueMessage } from "../../interface/message";
import FormYearDisplay from "../molecules/FormYearDisplay";
import RequestHistory from "../molecules/RequestHistory";

interface IssueLeftColumnParams {
}

const IssueLeftColumn = ({
}: IssueLeftColumnParams) => {
    const count = useSelector<RootState, number>(({ bus: { issueCount: ct } }) => ct);
    const processing = useSelector<RootState, boolean>(({ bus: { processing: pr } }) => pr);
    const stopWaiting = useSelector<RootState, boolean>(({ bus: { stopWaiting: sw } }) => sw);
    const hasLoan = useSelector<RootState, boolean>(({ bus: { yearReview } }) => Boolean(yearReview?.FICode || yearReview?.irsIncomeId));
    const yearRev = useSelector<RootState, IssueMessage | undefined>(({ bus: { yearReview: yr } }) => yr);
    const dispatch = useDispatch<AppDispatch>();

    const { sendJsonMessage } = useWebSocketContext();

    return (
        <Box className="stuck-column">
            <Box sx={[globalStyles.box, globalStyles.col]}>
                <Box
                    sx={[
                        globalStyles.pa2,
                        globalStyles.brXs,
                        count > 15 ? globalStyles.danger : count > 5 ? globalStyles.yellow : globalStyles.success,
                    ]}
                >
                    <Typography variant="h5">{count}</Typography>
                    <Typography>Waiting Inspection</Typography>
                </Box>
                <Box sx={globalStyles.col}>
                    <Button
                        id="next-btn"
                        variant="contained"
                        disabled={hasLoan}
                        loading={processing || !stopWaiting}
                        sx={[globalStyles.mt2]}
                        onClick={() => {
                            const newTabId = v4();
                            dispatch({ type: START_WAITING });
                            dispatch({ type: SET_PROCESSING, processing: true });
                            dispatch({ type: SET_TAB_ID, tabId: newTabId });
                            try {
                                sendJsonMessage({ command: 'issue-register', tabId: newTabId });
                            } catch (err) {
                                console.error(err);
                            }
                        }}
                    >
                        Next Request
                    </Button>
                    <Button
                        id="stop-btn"
                        variant="contained"
                        color="secondary"
                        disabled={stopWaiting}
                        sx={[globalStyles.mt2]}
                        onClick={() => {
                            if (processing) {
                                dispatch({ type: SET_PROCESSING, processing: false });
                            }
                            dispatch({ type: STOP_WAITING });
                            if (processing && !hasLoan) {
                                sendJsonMessage({ command: 'issue-deregister' });
                            }
                        }}
                    >
                        Stop Waiting
                    </Button>
                </Box>
            </Box>
            {(hasLoan && yearRev) && (
                <Box sx={[globalStyles.box, globalStyles.mt2]}>
                    <Typography fontSize={16} variant="h6">Loan Information</Typography>
                    <Typography fontSize={14}>{yearRev?.institutionCode}</Typography>
                    <Typography fontSize={14}>{yearRev?.loanNumber}</Typography>
                    <Typography fontSize={14}>{yearRev?.irsIncomeId}</Typography>
                </Box>
            )}
            {(yearRev && yearRev.requests) && (
                <Box sx={[globalStyles.box, globalStyles.mt2]}>
                    {Object.keys(yearRev.requests).map((formNumber) => (
                        <Box key={formNumber}>
                            <Table size="small">
                                <Typography noWrap fontSize={12}>{yearRev.requests?.[formNumber]?.[0]?.formDescription}</Typography>
                                <TableBody>
                                    {
                                        yearRev.requests[formNumber].map((req) => (
                                            <FormYearDisplay
                                                key={req.id}
                                                request={req}
                                            />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    ))}
                </Box>
            )}
            {(yearRev && yearRev.history) && (
                <RequestHistory history={yearRev.history} />
            )}
        </Box>
    );
};

export default IssueLeftColumn;

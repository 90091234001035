import { useMemo } from "react";
import { Grid, Paper, Typography } from "@mui/material";

import { StatusUpdateHistory } from "../../interface/message";
import globalStyles from "../../globalStyles";

interface RequestHistoryParams {
    history: StatusUpdateHistory[];
}

const RequestHistory = ({
    history,
}: RequestHistoryParams) => {
    const filtered = useMemo(() => history.filter(({ status }) => status.includes('TIA')), [history]);
    return (
        <Paper sx={[globalStyles.mt2, globalStyles.py2]}>
            <Typography variant="h6">TIA Request History</Typography>
            {
                filtered.length ? filtered.map(({ status, eventTime }) => (
                    <Grid container>
                        <Grid item xs={5}><Typography fontSize={12}>{status}:</Typography></Grid>
                        <Grid item xs={7}><Typography fontSize={12}>{new Date(eventTime).toLocaleDateString()} {new Date(eventTime).toLocaleTimeString()}</Typography></Grid>
                    </Grid>
                )) : (
                    <Typography>No History of TIA Sent found...</Typography>
                )
            }
        </Paper>
    );
};

export default RequestHistory;

interface LoadingParams {
}

const Loading = ({
}: LoadingParams) => (
    <div>
        <p>
            Loading
        </p>
    </div>
);

export default Loading;

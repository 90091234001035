import { Box, Checkbox, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import globalStyles from "../../globalStyles";
import { AppDispatch } from "../../configureStore";
import { ALL_YEARS_GOOD } from "../../redux/reducer/bus";

interface YearChecksParams {
    years: number[];
}

const YearChecks = ({
    years,
}: YearChecksParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const [yearsGood, setYearsGood] = useState<{ [year: string]: boolean }>(years.reduce((acc, yr) => ({ ...acc, [String(yr)]: false }), {}));

    useEffect(() => {
        const isAllGood = Object.values(yearsGood).every((yg) => yg);
        dispatch({ type: ALL_YEARS_GOOD, isAllGood });
    }, [yearsGood]);

    return (
        <div>
            {years.map((yr) => (
                <Box
                    key={`zoom-year-${yr}`}
                    sx={[globalStyles.flexCenterEnd, { alignSelf: 'flex-end' }]}
                >
                    <Typography>
                        {yr}
                    </Typography>
                    <Checkbox
                        checked={yearsGood[String(yr)]}
                        onClick={() => {
                            const isChecked = yearsGood[String(yr)];
                            setYearsGood({
                                ...yearsGood,
                                [String(yr)]: !isChecked,
                            });
                        }}
                        title={String(yr)}
                    />
                </Box>
            ))}
        </div>
    );
};

export default YearChecks;

import { Box, Button, Typography } from "@mui/material";

import globalStyles from "../globalStyles";

interface TimeoutParams {
}

const Timeout = ({
}: TimeoutParams) => (
    <Box>
        <Typography variant="h4">
            Your session timed out.
        </Typography>
        <Button
            variant="contained"
            onClick={() => { window.location.reload(); }}
            sx={globalStyles.mt2}
        >
            Reconnect
        </Button>
    </Box>
);

export default Timeout;
